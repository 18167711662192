import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import { Grid } from "@material-ui/core";

const sections = [
  { title: "Home", url: "/Home", isLive: true },
];

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `2px solid ${theme.palette.divider}`,
    backgroundColor: '#8AA1FF',
  },
  toolbarLink: {
    padding: theme.spacing(2),
    flexShrink: 0,
    fontSize: "1.2rem",
  },
  toolbarButton: {
    margin: theme.spacing(1),
    flexShrink: 0,
    fontSize: "1rem",
    size: "large",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  grid: {
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Toolbar component="nav" variant="dense" className={classes.toolbar}>
        <Grid className={classes.grid} container>
          <Grid item>
            {sections.map((section) => (
              <Link
                color={section.isLive ? "inherit" : "orange"}
                underline={section.isLive ? "hover" : "none"}
                noWrap
                key={section.title}
                variant="body1"
                href={section.url}
                className={classes.toolbarLink}
              >
                {section.title}
              </Link>
            ))}
          </Grid>
        </Grid>
      </Toolbar>
    </React.Fragment>
  );
}
