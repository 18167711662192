import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: theme.spacing(4)
    }
}));

const numberOfDotsNeeded = 3;

export default function ColorCheckbox(props) {
    const classes = useStyles();
    const { setDisableStart, setPickedColors } = props;

    const [checkboxState, setCheckboxState] = useState({
        'red': false,
        'blue': false,
        'green': false,
        'yellow': false,
        'orange': false,
        'purple': false,
        'black': false,
        'white': false
    });

    const handleChange = (event) => {
        setCheckboxState({
            ...checkboxState,
            [event.target.name]: event.target.checked,
        });

    };

    useEffect(() => {
        const pickedColors = Object.keys(checkboxState).filter((color) => {
            return checkboxState[color]
        })
        setDisableStart(pickedColors.length !== numberOfDotsNeeded)
        setPickedColors(pickedColors)
    }, [checkboxState])

    const { red, blue, green, yellow, orange, purple, black, white } = checkboxState;
    const error = [red, blue, green, yellow, orange, purple, black, white].filter((v) => v).length > numberOfDotsNeeded;

    return (
        <Box sx={{ display: 'flex' }} className={classes.box}>
            <FormControl
                required
                error={error}
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
            >
                <FormLabel component="legend">Pick the {numberOfDotsNeeded} colors of your dots</FormLabel>
                <FormGroup justifyContent='center' alignItems='center'>
                    {Object.keys(checkboxState).map((color) => {
                        return <FormControlLabel
                            control={
                                <Checkbox checked={checkboxState[color]} onChange={handleChange} name={color} color="primary" size="large" />
                            }
                            label={color}
                        />
                    })}
                </FormGroup>
                {error ? <FormHelperText error={true}>You must choose only {numberOfDotsNeeded} colors</FormHelperText> : <></>}
            </FormControl>
        </Box >
    );
}
