import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#EFBDEB',
        textAlign: 'center',
        justifyContent: "center",
        alignItems: "center",
        minHeight: '100vh',
        display: "flex",
        padding: theme.spacing(4)
    },
    stopButton: {
        textColor: '#FFFFFF',
        backgroundColor: '#FF5C5C',
        margin: theme.spacing(4),
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#FF5C5C',
        },
        '&:focus': {
            backgroundColor: '#FF5C5C',
        },
        '&:hover': {
            backgroundColor: '#FF5C5C',
        },
    }
}));


function sleep(seconds) {
    return new Promise((resolve) => {
        setTimeout(resolve, seconds * 1000);
    });
}


function speakIntro() {
    if ('speechSynthesis' in window) {
        var text = "Welcome to colors of the internet!"
        speakPhrase(text)
        text = "Partner 1 will start. I will call out a body part color and you will have 30 seconds to put it on your partners corresponding color."
        speakPhrase(text)
        text = "After the 30 seconds is up, the other partners turn will start."
        speakPhrase(text)
        text = "Try your best to place a body part, if you need to skip a turn you can."
        speakPhrase(text)
        text = "Finish the game at your own pace or until all the colors have been called out."
        speakPhrase(text)
        text = "We will begin in ten seconds.";
        speakPhrase(text)
    } else {
        alert("Sorry, your browser doesn't support text to speech! You won't be able to play on this device.");
    }
}

function speakPhrase(phrase) {
    var msg = new SpeechSynthesisUtterance();
    var voices = window.speechSynthesis.getVoices();
    msg.voice = voices[7];
    msg.rate = .7;
    msg.text = phrase;
    window.speechSynthesis.speak(msg);
}


async function startGame(partnerOne, partnerTwo) {
    var currentPlayerNumber = "one"
    const speakingDelay = 3;
    const secondsPerRound = 30
    while (partnerOne.dotColors.length > 0 || partnerTwo.dotColors.length > 0) {
        var currentPlayer = currentPlayerNumber === "one" && partnerOne.bodyParts.length > 0 ? partnerOne : partnerTwo;

        var bodyPartIndex = Math.floor(Math.random() * currentPlayer.bodyParts.length)
        var bodyPart = currentPlayer.bodyParts[bodyPartIndex]

        var dotColorIndex = Math.floor(Math.random() * currentPlayer.dotColors.length)
        var dotColor = currentPlayer.dotColors[dotColorIndex]

        var turnPhrase = `Partner ${currentPlayerNumber} it's your turn. Place your: ${bodyPart} on a dot on your partner that is the color: ${dotColor}`
        speakPhrase(turnPhrase)
        await sleep(10)
        var rephrase = `Partner ${currentPlayerNumber}. ${bodyPart} on ${dotColor}`
        speakPhrase(rephrase)


        if (currentPlayerNumber === "one") {
            currentPlayer.bodyParts.splice(bodyPartIndex, 1)
            currentPlayer.dotColors.splice(dotColorIndex, 1)
            currentPlayerNumber = "two"
        } else {
            currentPlayer.bodyParts.splice(bodyPartIndex, 1)
            currentPlayer.dotColors.splice(dotColorIndex, 1)
            currentPlayerNumber = "one"
        }
        await sleep(secondsPerRound + speakingDelay)
    }
}

export default function Game(props) {
    const classes = useStyles();

    const location = useLocation();

    useEffect(() => {
        const userColors = location.state.pickedColors.map((color) => { return Array(3).fill(color) }).flat()
        const bodyParts = ["hand", "hand", "foot", "foot", "crotch or butt", "leg", "leg", "arm", "arm", "head"]

        var partnerOne = {
            bodyParts: structuredClone(bodyParts),
            dotColors: structuredClone(userColors)
        }
        var partnerTwo = {
            bodyParts: structuredClone(bodyParts),
            dotColors: structuredClone(userColors)
        }

        speakIntro()

        async function startAsync() {
            await sleep(45)
            startGame(partnerOne, partnerTwo)
        }

        startAsync()

    }, [location]);


    return (
        <div className={classes.main}>
            <Button variant="contained" size='large' href="/Home" className={classes.stopButton}>Stop Game</Button>
        </div>
    );
}
