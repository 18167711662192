import './Home.css';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  grid: {
    alignItems: "center",
    justifyContent: "center",
  },
  mainText: {
    padding: theme.spacing(2)
  },
  beginButton: {
    textColor: '#000000',
    backgroundColor: '#859DFF',
    margin: theme.spacing(4),
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#314CB6',
    },
    '&:focus': {
      backgroundColor: '#314CB6',
    },
    '&:hover': {
      backgroundColor: '#314CB6',
    },
  }
}));


function Home() {
  const classes = useStyles();

  return (
    <div className="Home">
      <header className="Home-header">
        <Grid container className={classes.grid}>
          <Grid item md={6} className={classes.mainText}>
            <Typography component="h1" variant="h1" color="inherit">Colors of the Internet </Typography>
            <Typography component="h5" variant="h5" color="inherit"> Colors of the Internet is a game similar to Twister that is intended to be played with 2 people to create a fun and sensual time with your partner. </Typography>
            <Button variant="contained" size='large' href="/Setup" className={classes.beginButton}>Begin</Button>
          </Grid>
        </Grid>
      </header>
    </div>
  );
}

export default Home;
