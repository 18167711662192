import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import ColorCheckbox from './ColorCheckbox';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    grid: {
        alignItems: "center",
        justifyContent: "space-between",
    },
    main: {
        backgroundColor: '#EFBDEB',
        textAlign: 'center',
        justifyContent: "center",
        alignItems: "center",
        minHeight: '100vh',
        display: "flex",
        padding: theme.spacing(4)
    },
    mainParagraphText: {
        margin: theme.spacing(4),
    },
    stepsText: {
        margin: theme.spacing(1),
        textAlign: 'left'

    },
    beginButton: {
        textColor: '#000000',
        backgroundColor: '#859DFF',
        margin: theme.spacing(4),
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#314CB6',
        },
        '&:focus': {
            backgroundColor: '#314CB6',
        },
        '&:hover': {
            backgroundColor: '#314CB6',
        },
    }
}));

export default function Setup() {
    const classes = useStyles();

    const navigate = useNavigate();

    const [disableStart, setDisableStart] = useState(true);
    const [pickedColors, setPickedColors] = useState(true);

    function handleOnClick() {
        navigate('/Game', {
            state: {
                pickedColors: pickedColors
            }
        })
    }
    return (
        <div className={classes.main}>
            <div>
                <Grid container className={classes.grid} direction="column">
                    <Grid item justifyContent='center' alignItems='center' md={4}>
                        <Typography variant="h2">Setup</Typography>
                        <Typography variant="h6" className={classes.mainParagraphText}>* Things are about to get intimate, but have fun and change any setup or rules you see necessary.</Typography>
                    </Grid>
                    <Grid item justifyContent='center' alignItems='center' md={4}>
                        <Typography variant="h4" >Step 1</Typography>
                        <Typography variant="h6" className={classes.stepsText}>Similar to Twister there will be different colored dots, but instead of the dots being on the ground, they will be on your bodies.</Typography>
                        <Typography variant="h6" className={classes.stepsText}>You will need 3 different colored markers or paints. Watersoluble markers or paints are suggested.</Typography>
                    </Grid>
                    <Grid item justifyContent='center' alignItems='center' md={4}>
                        <Typography variant="h4" >Step 2</Typography>
                        <Typography variant="h6" className={classes.stepsText}>This is where the fun begins!</Typography>
                        <Typography variant="h6" className={classes.stepsText}>With your partner, put three dots for each of the three colors on your bodies. You can place them anywhere, but by the end you each should have 9 dots on your body.</Typography>
                    </Grid>
                    <Grid item justifyContent='center' alignItems='center' md={4}>
                        <Typography variant="h4" >Step 3</Typography>
                        <Typography variant="h6" className={classes.stepsText}></Typography>
                        <Typography variant="h6" className={classes.stepsText}>Decide which one of you will be partner 1 and partner 2.</Typography>
                    </Grid>

                    <Grid item justifyContent='center' alignItems='center' md={4} className={classes.mainParagraphText}>
                        <Typography variant="h2">The Rules</Typography>
                    </Grid>
                    <Grid item justifyContent='center' alignItems='center' md={4}>
                        <Typography variant="h4">Rule 1</Typography>
                        <Typography variant="h6" className={classes.stepsText}>If you don't feel comfortable at any point, stop the game or communicate to your partner that your not comfortable.</Typography>
                    </Grid>
                    <Grid item justifyContent='center' alignItems='center' md={4}>
                        <Typography variant="h4">Rule 2</Typography>
                        <Typography variant="h6" className={classes.stepsText}>Once the game starts, partner 1 will be given a color and a body part. They will have 30 seconds to place their body part on a colored dot on their partner of the same color that was called out.</Typography>
                        <Typography variant="h6" className={classes.stepsText}>After the 30 seconds, it will be the other partners turn.</Typography>
                    </Grid>
                    <Grid item justifyContent='center' alignItems='center' md={4}>
                        <Typography variant="h4">Rule 3</Typography>
                        <Typography variant="h6" className={classes.stepsText}>If you are unable to place your body part on your partner for a given round, you can either skip that round or you could lose</Typography>
                        <Typography variant="h6" className={classes.stepsText}>If you both make it to the end with all body parts covered, then you both win</Typography>
                    </Grid>
                    <Grid item justifyContent='center' alignItems='center' md={4}>
                        <Typography variant="h4">Rule 4</Typography>
                        <Typography variant="h6" className={classes.stepsText}>Have Fun!</Typography>
                        <Typography variant="h6" className={classes.stepsText}>This is a game intended to bring you closer to your partner. You may get into some awkward positions but roll with it</Typography>
                    </Grid>
                    <Grid item justifyContent='center' alignItems='center' md={4}>
                        <ColorCheckbox setDisableStart={setDisableStart} setPickedColors={setPickedColors} />
                    </Grid>
                    <Grid item justifyContent='center' alignItems='center' md={4}>
                        <Typography variant="h7">*Game currently does not work on mobile</Typography>
                    </Grid>
                    <Grid item justifyContent='center' alignItems='center' md={4}>
                        <Button variant="contained" size='large' disabled={disableStart} className={classes.beginButton} onClick={handleOnClick}>Start Game</Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
